<template>
  <div :class="[sideNavToggle.categoryOpen?'opened-categories':'default-categories']" id="navigation-mobile">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="ps-panel__header" style="background-color:#000">
        <div class="row">
          <div class="col-4 left-align">
            <router-link
              :to="{ name: 'my_profile' }"
              v-if="auth.isAuthenticated"
            >
              myPahukama
            </router-link>
            <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
          </div>
          <div class="col-4">
            <a class="ps-logo" href="/">
              <img
                src="/img/logo.png"
                alt="Pahukama"
                max-width="100%"
                height="auto"
              />
            </a>
          </div>
          <div class="col-4 right-align">
            <router-link :to="{ name: 'shop-now' }">ShopNowPH</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="ps-panel__header  mt-2">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <h3>Categories</h3>
        </div>
        <div class="col-3">
          <button class="btn close-side-menu" @click="[sideNavToggle.toggleCategoryOpen()]">CLOSE</button>
        </div>
      </div>
    </div>
    <div class="ps-panel__content">
      <ul class="menu--mobile">
        <li
          v-for="category in shop.categories"
          :key="category.id"
          class="menu-item-has-children has-mega-menu"
        >
          <router-link
            :to="{ name: 'category_products', params: { slug: category.slug } }"
          >
            <i class="icon-laundry"></i>
            {{ category.name }}
          </router-link>
          <div class="mega-menu">
            <div class="mega-menu__column">
              <h4>{{ category.name }}<span class="sub-toggle"></span></h4>
              <ul class="mega-menu__list">
                <li v-for="shop in category.shops" :key="shop.id">
                  <a href="#">{{ shop.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import CartMini from "@/components/CartMini.vue";
import { mapState } from "vuex";
export default {
  mixins: [global],
  data() {
    return {
      searchMethod: "Shop category",
      searchQuery: "",
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  components: {
    CartMini,
  },
};
</script>
<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
</script>

<style>
.default-categories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-categories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>

