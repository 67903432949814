import { reactive, ref } from "vue";
//export const menuOpen = ref(false);

export const sideNavToggle = reactive({
  menuOpen: false,
  toggleMenuOpen() {
    this.menuOpen = !this.menuOpen;
    console.log("toggle function called", this.menuOpen);
  },
  categoryOpen: false,
  toggleCategoryOpen() {
    this.categoryOpen = !this.categoryOpen;
  },
  searchOpen: false,
  toggleSearchOpen() {
    this.searchOpen = !this.searchOpen;
  },
  cartOpen: false,
  toggleCartOpen() {
    this.cartOpen = !this.cartOpen;
  },
  supportOpen: false,
  toggleSupportOpen() {
    this.supportOpen = !this.supportOpen;
  },
  test: 0,
  increment() {
    this.test++;
  },
});

/*export const toggle = ()  => {
    menuOpen.value = !menuOpen.value;
    console.log("toggled: ",menuOpen.value)
}*/
