<template>
  <div :class="[sideNavToggle.cartOpen?'opened-cart':'default-cart']" id="cart-mobile">
    <div class="ps-panel__header" style="background-color: #000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link
            :to="{ name: 'merchant_dashboard' }"
            v-if="auth.isAuthenticated"
            >Merchant</router-link
          >
          <router-link :to="{ name: 'merchant_register' }" v-else
            >Merchant</router-link
          >
        </div>
      </div>
    </div>
    <div class="ps-panel__header mt-2">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <h3>Shopping Cart</h3>
        </div>
        <div class="col-3">
          <button class="btn close-side-menu" @click="[sideNavToggle.toggleCartOpen()]">CLOSE</button>
        </div>
      </div>
    </div>
    <div class="navigation__content">
      <div class="ps-cart--mobile">
        <div v-if="cart.cartCount > 0" class="ps-cart__content">
          <div
            v-for="cartItem in cart.cartItems"
            :key="cartItem.id"
            class="ps-product--cart-mobile"
          >
            <div class="ps-product__thumbnail">
              <a href="#"><img src="../assets/product.png" alt="" /></a>
            </div>
            <div class="ps-product__content">
              <a
                class="ps-product__remove"
                href="#"
                @click.prevent="removeCartProduct(cartItem)"
                ><i class="icon-cross"></i
              ></a>
              <a href="">{{ cartItem.title }}</a>
              <p><strong>Sold by:</strong></p>
              <small
                >{{ cartItem.quantity }} x
                {{ cartItem.price | toCurrency }}</small
              >
            </div>
          </div>
        </div>
        <div v-else class="ps-cart__content">
          <div class="alert alert-danger" role="alert">
            No items in the cart
          </div>
        </div>
        <div class="ps-cart__footer">
          <h3>
            Sub Total:<strong>{{ cart.total }}</strong>
          </h3>
          <figure>
            <router-link class="ps-btn" :to="{ name: 'cart' }"
              >View Cart</router-link
            >
            <router-link class="ps-btn" :to="{ name: 'checkout' }"
              >Checkout</router-link
            >
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { sideNavToggle } from "./Navs/mobileNavs";
</script>

<script>
// import global from "@/mixins/global";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  props: ["product"],
  // mixins: [global],
  data() {
    return {
      cartContents: {},
      loading: false,
      totalCartItems: 0,
      cartTotal: 0,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    removeCartProduct(product) {
      if (confirm("Are you sure you want to remove")) {
        this.$store.dispatch("cart/removeCartProduct", {
          product: product,
        });
        // this.removeProductFromCart(id);
      }
    },
    addQuantity() {
      this.$store.dispatch("cart/addToCart", {
        product: this.cartProduct,
        quantity: 1,
      });
    },

    removeQuantity() {
      this.$store.dispatch("cart/removeFromCart", {
        product: this.cartProduct,
        quantity: 1,
      });
    },
  },
};
</script>

<style>
.default-cart {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
.opened-cart {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  transform: translateX(0%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
  display: block;
}
</style>
