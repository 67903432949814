<template>
  <header class="header header--1" data-sticky="true">
    <div class="header__top">
      <div class="ps-container">
        <div class="header__left">
          <div class="menu--product-categories">
            <div class="menu__toggle">
              <i class="icon-menu invert-color"></i>
              <span class="invert-color">Menu</span>
            </div>
            <div class="menu__content">
              <ul class="menu--dropdown">
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'home' }">
                    <i class="icon-laundry" />
                    Home
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'shop-now' }">
                    <i class="icon-laundry" />
                    Shop Now
                  </router-link>
                  <div class="mega-menu">
                    <div class="mega-menu__column">
                      <h4>Shop By Category<span class="sub-toggle"></span></h4>
                      <ul class="mega-menu__list">
                        <li
                          v-for="category in shop.categories"
                          :key="category.id"
                          class="menu-item-has-children2 has-mega-menu2"
                        >
                          <router-link
                            :to="{
                              name: 'category_products',
                              params: { slug: category.slug },
                            }"
                          >
                            <i class="icon-laundry"></i>
                            {{ category.name }}
                          </router-link>
                          <div class="mega-menu2">
                            <div class="mega-menu__column">
                              <h4>
                                {{ category.name
                                }}<span class="sub-toggle"></span>
                              </h4>
                              <ul class="mega-menu__list">
                                <li
                                  v-for="shop in category.shops"
                                  :key="shop.id"
                                >
                                  <a href="#">{{ shop.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'vouchers' }">
                    <i class="icon-laundry" />
                    BuyVoucherPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'topup_now' }">
                    <i class="icon-laundry" />
                    SmartTopUpPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'runner_requests' }">
                    <i class="icon-laundry" />
                    GetERunnerPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'prescriptions' }">
                    <i class="icon-laundry" />
                    SendPrescriptionPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'listings' }">
                    <i class="icon-laundry" />
                    ClassifiedListPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'dial_from_263' }">
                    <i class="icon-laundry" />
                    Recharge DialFrom263PH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'vouchers' }">
                    <i class="icon-laundry" />
                    BuyVoucherPH
                  </router-link>
                </li>
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'request_quotation' }">
                    <i class="icon-laundry" />
                    RequestQuotationPH
                  </router-link>
                </li>
                <li
                  v-if="false"
                  v-for="category in shop.categories"
                  :key="category.id"
                  class="menu-item-has-children has-mega-menu"
                >
                  <router-link
                    :to="{
                      name: 'category_products',
                      params: { slug: category.slug },
                    }"
                  >
                    <i class="icon-laundry"></i>
                    {{ category.name }}
                  </router-link>
                  <div class="mega-menu">
                    <div class="mega-menu__column">
                      <h4>
                        {{ category.name }}<span class="sub-toggle"></span>
                      </h4>
                      <ul class="mega-menu__list">
                        <li v-for="shop in category.shops" :key="shop.id">
                          <a href="#">{{ shop.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <a class="ps-logo" href="/">
            <img src="/img/logo.png" alt="Pahukama" />
          </a>
        </div>
        
      
        <div v-if="notHome" class="header__center adjusted">
          <HeaderSearch></HeaderSearch>
        </div>

        <div class="header__right">
          <div class="header__actions">
            <cart-mini></cart-mini>

            <div v-if="auth.isAuthenticated" class="ps-block--user-header">
              <div class="ps-block__left">|</div>
              <div class="ps-block__right ml-4">
                <router-link :to="{ name: 'my_profile' }">{{
                  auth.user.name.split(" ")[0]
                }}</router-link>
              </div>

              <div class="ps-block__right ml-4">
                <a href="#" @click.prevent="logout()">Logout</a>
              </div>
            </div>
            <div v-else class="ps-block--user-header">
              <div class="ps-block__left">|</div>
              <div class="dropdown ml-4">
                <a
                  v-if="!auth.isAuthenticated"
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownLoginMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Login
                </a>
                <a
                  v-else
                  class="icon-user dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownProfileMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownLoginMenuLink"
                >
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'login' }"
                    v-if="!auth.isAuthenticated"
                    >Login</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'register' }"
                    v-if="!auth.isAuthenticated"
                    >Register</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'my_profile' }"
                    v-else="auth.isAuthenticated"
                    >myPahukama</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'merchant_dashboard' }"
                    v-if="auth.isAuthenticated"
                    >Merchant</router-link
                  >
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'login' }"
                    v-else
                    >Merchant</router-link
                  >

                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="logout()"
                    v-if="auth.isAuthenticated"
                    >Logout</a
                  >
                </div>
              </div>

              <!--<div class="ml-4 mr-4">
                <router-link :to="{ name: 'login' }" style="font-weight: bold"
                  >Login</router-link
                >
              </div>
              
              <div class="ps-block__left">|</div>
              <div class="ml-4">
                <router-link
                  :to="{ name: 'register' }"
                  style="font-weight: bold"
                  >Register</router-link
                >
                <router-link :to="{name: 'auth'}" style="font-weight: bold">Register</router-link>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="navigation">
      <div class="ps-container">
        <div class="navigation__left">
          <div class="menu--product-categories">
            <div class="menu__toggle">
              <i class="icon-menu"></i>
              <span>Menu</span>
            </div>
            <div class="menu__content">
              <ul class="menu--dropdown">
                <li class="menu-item-has-children has-mega-menu">
                  <router-link :to="{ name: 'shop-now' }">
                    <i class="icon-arrow-right-circle" /> ShopNow
                  </router-link>
                  <div class="mega-menu">
                    <div class="mega-menu__column">
                      <h4>Shop By Category<span class="sub-toggle"></span></h4>
                      <ul class="mega-menu__list">
                        <li
                          v-for="category in shop.categories"
                          :key="category.id"
                        >
                          <router-link
                            :to="{
                              name: 'category_products',
                              params: { slug: category.slug },
                            }"
                          >
                            <i class="icon-arrow-right"></i>
                            {{ category.name }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <router-link :to="{ name: 'vouchers' }">
                    <i class="icon-arrow-right-circle" />
                    BuyVoucherPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'topup_now' }">
                    <i class="icon-arrow-right-circle" />
                    SmartTopUpPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'runner_requests' }">
                    <i class="icon-arrow-right-circle" />
                    GetERunnerPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'prescriptions' }">
                    <i class="icon-arrow-right-circle" />
                    SendPrescriptionPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'listings' }">
                    <i class="icon-arrow-right-circle" />
                    ClassifiedListPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'dial_from_263' }">
                    <i class="icon-arrow-right-circle" />
                    Recharge DialFrom263PH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'vouchers' }">
                    <i class="icon-arrow-right-circle" />
                    BuyVoucherPH
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'request_quotation' }">
                    <i class="icon-arrow-right-circle" />
                    RequestQuotationPH
                  </router-link>
                </li>
                <li
                  v-if="false"
                  v-for="category in shop.categories"
                  :key="category.id"
                >
                  <router-link
                    :to="{
                      name: 'category_products',
                      params: { slug: category.slug },
                    }"
                  >
                    <i class="icon-star"></i>
                    {{ category.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="navigation__right">
          <ul class="menu">
            <li class="menu-item">
              <router-link :to="{ name: 'vouchers' }">BuyVoucherPH</router-link>
            </li>
            <li class="menu-item">
              <router-link :to="{ name: 'topup_now' }"
                >SmartTopUpPH</router-link
              >
            </li>
            <li class="menu-item">
              <router-link :to="{ name: 'runner_requests' }"
                >GetERunnerPH</router-link
              >
            </li>
            <li class="menu-item">
              <router-link :to="{ name: 'prescriptions' }"
                >SendPrescriptionPH</router-link
              >
            </li>

            <li class="menu-item">
              <router-link :to="{ name: 'listings' }"
                >ClassifiedListPH</router-link
              >
            </li>

            <li class="menu-item" style="display: none">
              <router-link :to="{ name: 'merchant_register' }"
                >Become a Merchant</router-link
              >
            </li>

            <li
              class="menu-item"
              v-if="auth.isAuthenticated"
              style="display: none"
            >
              <router-link :to="{ name: 'prescriptions' }"
                >myPrescriptions</router-link
              >
            </li>
            <li
              class="menu-item"
              v-if="auth.isAuthenticated"
              style="display: none"
            >
              <router-link :to="{ name: 'my_profile' }">myProfile</router-link>
            </li>
            <!--            <li class="menu-item" v-if="auth.isAuthenticated">-->
            <!--              <router-link :to="{name: 'my_orders'}">myOrders</router-link>-->
            <!--            </li>-->
          </ul>
          <ul class="navigation__extra">
            <li>
              <router-link :to="{ name: 'merchant_register' }"
                >Merchant</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import HeaderSearch from "../HeaderSearch";
import HeaderSearch2 from "../HeaderSearch2";
import CartMini from "@/components/CartMini.vue";
import Categories from "./Categories";
import { mapState } from "vuex";
export default {
  data() {
    return {
      currentPlace: {},
      notHome: true,
    };
  },
  components: {
    HeaderSearch,
    HeaderSearch2,
    CartMini,
    Categories
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    this.notHome = this.$route.path != "/";
    // this.notHome = this.$route.path != "/home-store-front";
  },
  watch: {
    $route(to, from) {
      this.notHome = this.$route.path != "/";
      // this.notHome = this.$route.path != "/home-store-front";
    },
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "home" });
    },
  },
};
</script>
