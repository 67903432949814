<template>
  <div>
    <header class="header header--mobile" data-sticky="true">
      <div class="header__top">
        <div class="header__left">
          <p>Welcome to Pahukama Online Shopping Store !</p>
        </div>
        <div class="header__right">
          <ul class="navigation__extra">
            <li>
              <router-link :to="{ name: 'merchant_register' }"
                >Sell on Pahukama</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="navigation--mobile">
        <div class="navigation__left">
          <div class="hover-pointer" @click="[sideNavToggle.toggleMenuOpen()]"
            
            style="color: white; margin-right: 10px; margin-left: -10px"
          >
            <i
              class="icon-menu"
              style="font-size: 25px; font-weight: bold; color: #f9a51e"
            ></i>
        </div>
          <a class="ps-logo" href="/">
            <img height="50" src="/img/logo.png" alt="Logo"/>
            <p class="position-absolute" style="top: 80%; right: -40%; color: #f9a51e; font-weight: bold;">ShopNow</p>
        </a>
        </div>
        <div class="navigation__right">
          <div class="header__actions">
            <cart-mini></cart-mini>
            <div class="ps-block--user-header">
              <div class="ps-block__left"></div>
              <div class="ps-block__right">
                <div class="dropdown ml-4">
                  <a
                    v-if="!auth.isAuthenticated"
                    class="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMiddleLoginMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Login
                  </a>
                  <a
                    v-else
                    class="icon-user dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownProfileMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMiddleLoginMenuLink"
                  >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'login' }"
                      v-if="!auth.isAuthenticated"
                      >Login</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'register' }"
                      v-if="!auth.isAuthenticated"
                      >Register</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'login' }"
                      v-if="!auth.isAuthenticated"
                      >myPahukama</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'my_profile' }"
                      v-else="auth.isAuthenticated"
                      >myPahukama</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'merchant_dashboard' }"
                      v-if="auth.isAuthenticated"
                      >Merchant</router-link
                    >
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'login' }"
                      v-else
                      >Merchant</router-link
                    >
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.prevent="logout()"
                      v-if="auth.isAuthenticated"
                      >Logout</a
                    >
                  </div>
                </div>
                <!--<router-link
                  :to="{ name: 'login' }"
                  v-if="!auth.isAuthenticated"
                  >Login</router-link
                >
                <a
                  href="#"
                  @click.prevent="logout()"
                  v-if="auth.isAuthenticated"
                  >Logout</a
                >-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Previouse top buttons were here -->
      <!-- code placed in docs -->

      <div class="ps-search--mobile">
        <div
          style="
            border: white solid 2px;
            border-radius: 5px;
            margin-right: -15px;
            margin-left: -15px;
            padding: 3px 15px;
            margin-top: 15px;
          "
        >
          <!-- <span
            style="
              
              border-radius: 5px;
              color: #f9a51e;
              font-weight: bold;
              font-style: italic;
              font-size: 1.1rem;
              padding: 2px 8px;
            "
          >
            What service or product can we help you find today ?
          </span> -->
          <HeaderSearch></HeaderSearch>
        </div>
        <span
          style="
            display: inline-block;
            border: 2px solid white;
            border-radius: 5px;
            color: white;
            font-weight: bolder;
            font-style: italic;
            font-size: 1.1rem;
            padding: 5px 5px;
            box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5);
            margin-top: 9px;
          "
        >
          Pahukama© (pH™) More Value For Your US$ Remittances
        </span>

        <!-- <div style="display: flex" v-if="isHome">
          <span
            style="padding: 5px; background-color: black; width: 20vw"
            v-for="product1 in shopOneProducts"
          >
            <router-link
              :to="{ name: 'Product', params: { slug: product1.slug } }"
            >
              <img
                style="width: 100%; aspect-ratio: 1; object-fit: cover"
                v-lazy="product1.images[0].path"
              />
            </router-link>
          </span>
        </div> -->

        <div class="row">
          <div class="col-3 left-align">
            <a href="/products" style="text-decoration: none">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                  background-color: white;
                  padding: 10px;
                  border-radius: 5px;
                  box-shadow: 0 0 10px 0 orange;
                  transition: box-shadow 0.3s;
                "
              >
                <h5
                  style="
                    color: rgb(5, 3, 0);
                    font-size: 10px;
                    font-weight: bold;
                    margin: 0;
                  "
                >
                  Products
                </h5>
              </div>
            </a>
          </div>
          <div class="col-3 center-align">
            <a href="/deals/services" style="text-decoration: none">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                  background-color: white;
                  padding: 10px;
                  border-radius: 5px;
                  box-shadow: 0 0 10px 0 orange;
                  transition: box-shadow 0.3s;
                "
              >
                <h5
                  style="
                    color: rgb(5, 3, 0);
                    font-size: 10px;
                    font-weight: bold;
                    margin: 0;
                  "
                >
                  Services
                </h5>
              </div>
            </a>
          </div>
          <div class="col-3 right-align">
            <a href="/shop" style="text-decoration: none">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                  background-color: white;
                  padding: 10px;
                  border-radius: 5px;
                  box-shadow: 0 0 10px 0 orange;
                  transition: box-shadow 0.3s;
                "
              >
                <h5
                  style="
                    color: rgb(5, 3, 0);
                    font-size: 10px;
                    font-weight: bold;
                    margin: 0;
                  "
                >
                  Shops
                </h5>
              </div>
            </a>
          </div>
          <div class="col-3 center-align">
            <a href="/merchant-register" style="text-decoration: none">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                  background-color: white;
                  padding: 10px;
                  border-radius: 5px;
                  box-shadow: 0 0 10px 0 orange;
                  transition: box-shadow 0.3s;
                "
              >
                <h5
                  style="
                    color: rgb(5, 3, 0);
                    font-size: 10px;
                    font-weight: bold;
                    margin: 0;
                  "
                >
                  Merchants
                </h5>
              </div>
            </a>
          </div>
        </div>

        <div>
          <div
            style="
              background-color: white;
              margin-right: -18px;
              margin-left: -18px;
              max-width: 95;
              margin-top: 13px;
            "
          >
            <img v-lazy="'/img/platform.png'" alt="Grand Open" />
            <HomeSlideshowFeaturedBrands2
              v-if="isHome"
            ></HomeSlideshowFeaturedBrands2>
          </div>

          <div
            style="
              background-color: rgb(248, 241, 241);
              margin-right: -20px;
              margin-left: -20px;
              margin-bottom: -8px;
              margin-top: 10px;
            "
          >
            <div class="horizontal-bar">
              <h6>pH's Principles || Remit Securely || Protection</h6>
            </div>

            <div
              style="background-color: black; padding: 10px; margin-top: -10px"
            >
              <div class="row">
                <div class="col-3 left-align">
                  <a href="/shop/pahukama-colcom" style="text-decoration: none">
                    <div
                      style="
                        display: flex;
                        margin-left: 4px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        background-color: white;
                        padding: 10px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px 0 orange;
                        transition: box-shadow 0.3s;
                      "
                    >
                      <img
                        src="/img/colc.png"
                        alt="Colcom"
                        style="
                          width: 120px;
                          height: 120px;
                          max-width: 123%;
                          margin-top: -4px;
                        "
                      />
                    </div>
                  </a>
                </div>
                <div class="col-3 center-align">
                  <a
                    href="/shop/pahukama-butcherbox"
                    style="text-decoration: none"
                  >
                    <div
                      style="
                        display: flex;
                        margin-left: -1px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        background-color: white;
                        padding: 10px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px 0 orange;
                        transition: box-shadow 0.3s;
                      "
                    >
                      <img
                        src="/img/butcherb.png"
                        alt="ButcherBox"
                        style="
                          width: 120px;
                          height: 120px;
                          max-width: 123%;
                          margin-top: -4px;
                        "
                      />
                    </div>
                  </a>
                </div>
                <div class="col-3 right-align">
                  <a
                    href="/shop/greenwood-pharmacy-online"
                    style="text-decoration: none"
                  >
                    <div
                      style="
                        display: flex;
                        margin-left: -7px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        background-color: white;
                        padding: 10px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px 0 orange;
                        transition: box-shadow 0.3s;
                      "
                    >
                      <img
                        src="/img/green.png"
                        alt="Greenwood"
                        style="width: 120px; height: 120px; max-width: 123%"
                      />
                    </div>
                  </a>
                </div>
                <div class="col-3 left-align">
                  <a href="/shop-now" style="text-decoration: none">
                    <div
                      style="
                        display: flex;
                        margin-left: -12px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        background-color: white;
                        padding: 10px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px 0 orange;
                        transition: box-shadow 0.3s;
                      "
                    >
                      <img
                        src="/img/phs.png"
                        alt="pH Supermarket"
                        style="
                          width: 120px;
                          height: 120px;
                          max-width: 123%;
                          margin-top: -4px;
                        "
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div style="display: flex" v-if="isHome">
              <span style="padding: 5px 0px">
                <router-link
                  :to="{ name: 'dial_from_263' }"
                  class="ls-hover-shadow"
                >
                  <img
                    style="width: 83%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button1.png'"
                  />
                </router-link>
              </span>
              <span style="padding: 5px 0px">
                <router-link
                  class="ls-hover-shadow"
                  :to="{ name: 'runner_requests' }"
                >
                  <img
                    style="width: 83%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button2.png'"
                  />
                </router-link>
              </span>

              <span style="padding: 5px 0px">
                <router-link
                  class="ls-hover-shadow"
                  :to="{ name: 'topup_now' }"
                >
                  <img
                    style="width: 83%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button3.png'"
                  />
                </router-link>
              </span>
            </div>
            <div style="background-color: black; padding: 1px; height: 18px">
              <span
                style="
                  color: white;
                  font-weight: bold;
                  font-style: italic;
                  font-size: 12px;
                "
              >
                Zim Delivery or Pick Up Available on All Orders
              </span>
            </div>
          </div>

          <div
            style="
              background-color: white;
              margin-right: -20px;
              margin-left: -20px;
              margin-bottom: -15px;
              margin-top: 15px;
            "
            v-if="isHome"
          >
            <HomeStoreFront2></HomeStoreFront2>
          </div>

          <div
            style="
              background-color: white;
              margin-right: -20px;
              margin-left: -20px;
              margin-bottom: -15px;
              margin-top: -15px;
            "
          >
            <div style="display: flex" v-if="isHome">
              <div>
                <span style="padding: 5px 0px">
                  <a href="/simple_pay_direct" class="ls-hover-shadow">
                    <img
                      style="
                        width: 93%;
                        aspect-ratio: 1;
                        margin-top: 10px;
                        object-fit: contain;
                      "
                      src="/img/button4.png"
                    />
                  </a>
                </span>
              </div>
              <span style="padding: 5px 10px">
                <a href="/specialists-jobs-tasks" class="ls-hover-shadow">
                  <img
                    style="
                      width: 93%;
                      aspect-ratio: 1;
                      margin-left: 12px;
                      object-fit: contain;
                    "
                    v-lazy="'/img/button5.png'"
                  />
                </a>
              </span>
              <span style="padding: 5px 10px">
                <a href="/vouchers" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button8.png"
                  />
                </a>
              </span>
            </div>
          </div>
          <!-- <div style="background-color: white;  margin-right: -15px; margin-left: -15px;  margin-right: -15px;
            margin-bottom: -15px; margin-top: 15px;"  v-if="isHome">
          <HomeStoreFront2></HomeStoreFront2>
        </div> -->
          <div
            style="
              background-color: white;
              margin-right: -15px;
              margin-left: -15px;
              margin-right: -15px;
              margin-bottom: -15px;
              margin-top: 15px;
            "
            v-if="isHome"
          >
            <HomeStoreFront3></HomeStoreFront3>
          </div>

          <div
            style="
              background-color: white;
              margin-right: -20px;
              margin-left: -20px;
              margin-bottom: -15px;
              margin-top: -15px;
            "
          >
            <div style="display: flex" v-if="isHome">
              <span style="padding: 5px 0px">
                <a href="/shop/pahukama-hardware" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button7.png"
                  />
                </a>
              </span>
              <span style="padding: 5px 0px">
                <a href="/shop-now" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    v-lazy="'/img/button6.png'"
                  />
                </a>
              </span>
              <span style="padding: 5px 0px">
                <a href="/shop/pahukama-treats" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button10.png"
                  />
                </a>
              </span>
            </div>
          </div>
          <!-- <div style="background-color: white;  margin-right: -15px; margin-left: -15px;  margin-right: -15px;
            margin-bottom: -15px; margin-top: 15px;"  v-if="isHome">
          <HomeStoreFront2></HomeStoreFront2>
        </div> -->
          <div
            style="
              background-color: white;
              margin-right: -15px;
              margin-left: -15px;
              margin-right: -15px;
              margin-bottom: -15px;
              margin-top: 15px;
            "
            v-if="isHome"
          >
            <HomeStoreFront4></HomeStoreFront4>
          </div>

          <div
            style="
              background-color: white;
              margin-right: -20px;
              margin-left: -20px;
              margin-bottom: -15px;
              margin-top: -15px;
            "
          >
            <div style="display: flex" v-if="isHome">
              <span style="padding: 5px 0px">
                <a href="/listings/category" class="ls-hover-shadow">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button9.png"
                  />
                </a>
              </span>
              <span style="padding: 5px 10px">
                <a class="ls-hover-shadow" href="/prescriptions">
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button11.png"
                  />
                </a>
              </span>
              <span style="padding: 5px 0px">
                <a
                  href="/shop/pahukama-building-materials"
                  class="ls-hover-shadow"
                >
                  <img
                    style="width: 93%; aspect-ratio: 1; object-fit: contain"
                    src="/img/button12.png"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- <Categories></Categories> -->
  </div>
</template>
<script setup>
import { sideNavToggle } from "./mobileNavs";

</script>
<script>
import { mapState } from "vuex";
import axios from "axios";
import CartMini from "@/components/CartMini.vue";
import Categories from "./Categories";
import HeaderSearch from "../HeaderSearch";
import HeaderSearch2 from "../HeaderSearch2";
import HomeSlideshowFeaturedBrands2 from "../../views/pages/Home/HomeSlideshowFeaturedBrands2";
import HomeStoreFront from "../../views/pages/Home/HomeStoreFront.vue";
import HomeStoreFront2 from "../../views/pages/Home/HomeStoreFront2.vue";
import HomeStoreFront3 from "../../views/pages/Home/HomeStoreFront3.vue";
import HomeStoreFront4 from "../../views/pages/Home/HomeStoreFront4.vue";
import HomeStoreFront5 from "../../views/pages/Home/HomeStoreFront5.vue";
import Services from "../../views/pages/Home/Services.vue";

export default {
  components: {
    HeaderSearch,
    HeaderSearch2,
    Categories,
    CartMini,
    HomeSlideshowFeaturedBrands2,
    Services,
    HomeStoreFront,
    HomeStoreFront2,
    HomeStoreFront3,
    HomeStoreFront4,
    HomeStoreFront5,
  },
  data() {
    return {
      currentPlace: {},
      loading: true,
      shops: [],
      shopOne: null,
      shopOneProducts: null,
      shopTwo: null,
      shopTwoProducts: null,
      shopThree: null,
      shopThreeProducts: [],
      shopFour: null,
      shopFourProducts: [],
      isHome: true,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    var that = this;
    this.isHome = this.$route.path == "/";
    this.getShops();
  },
  watch: {
    $route(to, from) {
      this.isHome = this.$route.path == "/";
    },
  },
  methods: {
    getShops() {
      this.shops.length = 0;
      this.$loading(true);

      axios
        .get("shops/list")
        .then((response) => {
          this.shops = response.data;
          let limit = 6;
          if (this.shops.length > 0) {
            this.shopOne = this.shops[0];
            if (this.shopOne) {
              this.shopOneProducts = this.getShopProducts(this.shopOne, limit);
            }
          }

          if (this.shops.length > 1) {
            this.shopTwo = this.shops[1];
            if (this.shopTwo) {
              this.shopTwoProducts = this.getShopProducts(this.shopTwo, limit);
            }
          }
          if (this.shops.length > 2) {
            this.shopThree = this.shops[2];
            if (this.shopThree) {
              this.shopThreeProducts = this.getShopProducts(
                this.shopThree,
                limit
              );
            }
          }
          if (this.shops.length > 3) {
            this.shopFour = this.shops[2];
            if (this.shopFour) {
              this.shopFourProducts = this.getShopProducts(
                this.shopFour,
                limit
              );
            }
          }
          this.$loading(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          this.$loading(false);
        });
    },

    getShopProducts(shop, limit = 6) {
      let shopProducts = [];
      axios
        .get(`products?shop=${shop.id}&limit=${limit}`)
        .then((response) => {
          response.data.data.filter((product, i) => {
            if (i < limit) {
              shopProducts.push(product);
            }
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      return shopProducts;
    },

    setPlace(place) {
      this.currentPlace = place;
    },
    logout() {
      this.$store.dispatch("auth/logout");
      localStorage.removeItem("authData");
      location.reload();
    },
  },
};
</script>

<style scoped>
/* @import url('/css/common12d2.css'); */

.category-container {
  display: flex;
}

.horizontal-bar {
  background-color: #6c757d; /* Grey-blue color */
  height: 20px; /* Adjust the height as needed */
  width: 100%; /* Fill the entire width of the container */
  position: relative; /* Enable positioning of the text */
  margin-top: -10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5);
}

.horizontal-bar h6 {
  position: absolute; /* Position the text absolutely within the bar */
  top: 50%; /* Position it vertically in the middle */
  transform: translateY(-50%); /* Adjust vertical alignment */
  left: 12px; /* Adjust the left spacing */
  right: -16px; /* Adjust the right spacing */
  color: white; /* Text color */
  word-spacing: 4px;
  font-size: 11px; /* Font size */
  text-align: right; /* Center the text */
  font-weight: bold;
  margin-right: 97px; /* Adjust the spacing between the texts */
}
.mobile-categories {
  display: none;
}

@media screen and (max-width: 575px) {
  .mobile-categories {
    display: inline-flex;
    overflow-x: scroll;
    width: 100%;
  }

  .desktop-categories {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .category-container {
    /* overflow-x: scroll;
    touch-action: pan-x; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.spacing {
  margin-left: 120px; /* Adjust the value as needed */
}

.category-item {
  flex: 1;
  padding: 0.5rem 0;
  border: 1px solid rgba(38, 38, 38, 0.132);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 575px) {
  .category-item {
    /* min-width: 12rem; */
    padding: 10px 0;
  }
}

.category-item:hover {
  cursor: pointer;
}

.category-item:hover > * {
  color: #757575;
  -webkit-text-stroke: 0.5px #f79b07;
}

.category-title {
  margin: 0;
  font-size: 0.82em;
  color: #747474;
  text-shadow: 1px 4px 6px #def, 0 0 0 #000, 1px 4px 6px #def;
  text-align: center;
}

.category-icon {
  width: 50px;
  height: 54px;
}

@media screen and (max-width: 575px) {
  .category-icon {
    width: 30px;
    height: 34px;
  }

  .category-title {
    font-size: 10px;
  }

  .col-cat4 {
    padding: 0;
  }

  .ps-search--mobile {
    padding: 1rem 2rem 1rem 2rem;
  }
}
</style>
